// initialize as default
var serverAddr = "https://initialview.com";
var staticAddr = "https://initialview-prod.global.ssl.fastly.net/";
var ameliaAddr = "https://amelia.initialview.com";

if (window.location.hostname == "ameliatest.initialview.com") {
   serverAddr = "https://test.initialview.com";
   staticAddr = "https://test.initialview.com";
   ameliaAddr = "https://ameliatest.initialview.com";
}
else if (window.location.hostname == "lab-nk-amelia.initialview.com") {
   serverAddr = "https://lab-nk.initialview.com";
   staticAddr = "https://lab-nk.initialview.com";
   ameliaAddr = "https://lab-nk-amelia.initialview.com";
}
else if (window.location.hostname == "lab-nb-amelia.initialview.com") {
   serverAddr = "https://lab-nb.initialview.com";
   staticAddr = "https://lab-nb.initialview.com";
   ameliaAddr = "https://lab-nb-amelia.initialview.com";
}
else if (window.location.hostname == "lab-sc-amelia.initialview.com") {
   serverAddr = "https://lab-sc.initialview.com";
   staticAddr = "https://lab-sc.initialview.com";
   ameliaAddr = "https://lab-sc-amelia.initialview.com";
}
else if (window.location.hostname == "lab-d5-amelia.initialview.com") {
   serverAddr = "https://lab-d5.initialview.com";
   staticAddr = "https://lab-d5.initialview.com";
   ameliaAddr = "https://lab-d5-amelia.initialview.com";
}
else if (window.location.hostname == "lab-d1-amelia.initialview.com") {
   serverAddr = "https://lab-d1.initialview.com";
   staticAddr = "https://lab-d1.initialview.com";
   ameliaAddr = "https://lab-d1-amelia.initialview.com";
}
else if (window.location.hostname == "lab-d2-amelia.initialview.com") {
   serverAddr = "https://lab-d2.initialview.com";
   staticAddr = "https://lab-d2.initialview.com";
   ameliaAddr = "https://lab-d2-amelia.initialview.com";
}
else if (window.location.hostname == "lab-d3-amelia.initialview.com") {
   serverAddr = "https://lab-d3.initialview.com";
   staticAddr = "https://lab-d3.initialview.com";
   ameliaAddr = "https://lab-d3-amelia.initialview.com";
}
else if (window.location.hostname == "lab-nsk-amelia.initialview.com") {
   serverAddr = "https://lab-nsk.initialview.com";
   staticAddr = "https://lab-nsk.initialview.com";
   ameliaAddr = "https://lab-nsk-amelia.initialview.com";
}
else if (window.location.hostname == "lab-wq-amelia.initialview.com") {
   serverAddr = "https://lab-wq.initialview.com";
   staticAddr = "https://lab-wq.initialview.com";
   ameliaAddr = "https://lab-wq-amelia.initialview.com";
}
else if (window.location.hostname == "amelia-cn.initialview.com") {
   serverAddr = "https://cn.initialview.com";
   staticAddr = "https://cn.initialview.com";
   ameliaAddr = "https://amelia-cn.initialview.com";
}
else if (window.location.hostname == "amelia-test-cn.initialview.com") {
   serverAddr = "https://test-cn.initialview.com";
   staticAddr = "https://test-cdn-ali.initialview.com";
   ameliaAddr = "https://amelia-test-cn.initialview.com";
}
else if ("localhost" == window.location.hostname) {
   serverAddr = "https://amelia.localhost";
   staticAddr = "https://amelia.localhost";
   ameliaAddr = "wss://amelia.localhost";
 }


export default {
   serverAddr: serverAddr,
   staticAddr: staticAddr,
   ameliaAddr: ameliaAddr,
}
