import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiDown: false
  },
  mutations: {
    apiDown (state) {
      state.apiDown = true
    },
    apiUp (state) {
      state.apiDown = false
    }
  },
  actions: {}
});
