import Vue from "vue";
import Router from "vue-router";
import apiClient from "./utils/apiClient.js";
import moment from "moment-timezone";
import store from "@/store.js";

Vue.use(Router);

var router = new Router({
  mode: "history",
  //base: process.env.BASE_URL,
  routes: [{
      path: "/",
      name: "amelia",
      component: () => import("./views/Amelia.vue"),
      meta: {
        title: "Amelia"
      }
    },
    {
      path: "/review-student",
      name: "review-student",
      component: () => import("./views/AmeliaRevStudent.vue"),
      meta: {
        title: "Review"
      }
    },
    {
      path: "/review-writing",
      name: "review-writing",
      component: () => import("./views/AmeliaRevWriting.vue"),
      meta: {
        title: "Review"
      }
    },
    {
      path: "/re-info",
      name: "review-info",
      component: () => import("./info-pages/AmeliaReInfo.vue"),
      meta: {
        title: "Review"
      }
    },
    {
      path: "/integration-docs",
      name: "integration-docs",
      component: () => import("./info-pages/IntegrationDocs.vue"),
      meta: {
        title: "Review"
      }
    },
    {
      path: "/junior-feedback",
      name: "junior-feedback",
      component: () => import("./info-pages/JuniorFeedback.vue"),
      meta: {
        title: "Junior Feedback"
      }
    },
    {
      path: "/stars-info",
      name: "stars-info",
      component: () => import("./info-pages/AboutStars.vue"),
      meta: {
        title: "Review"
      }
    },
    {
      path: "/dean-z",
      name: "review-audio",
      component: () => import("./views/AmeliaRevAudio.vue"),
      meta: {
        title: "Review"
      }
      // props: {
      //   mpage: "my-interviews"
      // }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/AmeliaLogin.vue"),
      meta: {
        title: "Login"
      }
    },
    {
      path: "/login/iframelocalstorage",
      name: "login",
      component: () => import("./views/AmeliaLoginIframeLocalStorage.vue"),
      meta: {
        title: "Login"
      }
    },
    {
      path: "/epic",
      name: "epic",
      component: () => import("./views/Epic.vue"),
      meta: {
        title: "EPIC"
      }
    },
    {
      path: "/tuesday-talks",
      name: "tuesday-talks",
      component: () => import("./views/TuesdayTalks.vue"),
      meta: {
        title: "Tuesday Talks"
      }
    },
    {
      path: "/tuesdaytalks",
      redirect: "/tuesday-talks"
    },
    {
      path: '/:language(en|zh)?/hsprofiles',
      component: () => import('./views/HSProfileBrowser.vue'),
      meta: {
        title: "High School Profile"
      }
    },
  ]
});

router.afterEach((to, from) => {
  // Report every page load
  // Notice this will not catch parameter or query changes
  // See https://router.vuejs.org/guide/advanced/navigation-guards.html
  try {
    // from.fullPath is NOT the correct path. it seems to be either null or
    // "/", which is not useful.
    console.log("Trying to contact rmal/page")
    //~ Vue.http.get("/api/rmal/page/", {params:{prev_page:from.fullPath, region:moment.tz.guess()}}).then(()=>{console.log("SUCCESS contacting rmal/page")}).catch((e)=>{console.error("Could not contact rmal/page",e)})
    //~ Vue.http.get(`/api/rmal/page/?prev_page=${from.fullPath}&region=somewhere`).then(()=>{console.log("SUCCESS contacting rmal/page")}).catch((e)=>{console.error("Could not contact rmal/page",e)})
    //~ Vue.http.get(`/api/rmal/page/?prev_page=${window.prev_page||"empty_route"}&region=somewhere`).then(()=>{console.log("SUCCESS contacting rmal/page")}).catch((e)=>{console.error("Could not contact rmal/page",e)})
    // This is unhappy on the server side, but oh well. It does work on the client side. In fact, I dont' want this to succeed on the server side.
    apiClient.get(`/api/rmal/page/?prev_page=${document.referrer||"empty_route"}&region=${moment.tz.guess()}`).then(()=>{
      console.log("SUCCESS contacting rmal/page")
      store.commit('apiUp')
    }).catch((e)=>{
      console.error("Could not contact rmal/page",e)
      store.commit('apiDown')
    })

  } catch (e){
    console.error("Could not contact rmal/page",e)
  }

  //~ console.log("fetching instead...")
  //~ fetch('/api/rmal/page/')
})

export default router
