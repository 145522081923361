import env from "../utils/env.js";
import axios from "axios";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

if (sessionStorage.apitoken) {
  headers["Authorization"] = "Token " + sessionStorage.apitoken;
}

export default axios.create({
  baseURL: env.ameliaAddr,
  //~ baseURL: 'https://ameliatest.initialview.com',
  //~ baseURL: 'localhost:8080',
  //~ withCredentials: true,
  headers: headers
});
